import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"

const ThanksPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Thank you for contacting me</title>
      
      </Helmet>
      <div className="two-grids -about">
        <div className="post-thumbnail" style={{backgroundImage: `url('/assets/einar-sydney.jpg')`, marginBottom: 0}}>
          <h1 className="post-title">Thanks</h1>
        </div>
        <div>  
            <p>I'll get in touch soon</p>
            </div>
          
          
          
      </div>
      
    </Layout>
  )
}
export default ThanksPage

